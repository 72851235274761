
.HomeContainer {
  background-color: #ffffff;
  min-width: 100vw;
  min-height: 100vh;
}
.HomeNavBarMain {
  backdrop-filter: blur(10px); /* Apply a blur effect to the background */
  background-color: rgba(255, 255, 255, 1); /* Add some transparency to the element */
  width: 100vw;
  height: 55px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.00);
  border-bottom: 1px solid #e7e7e7;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
}
.HomeNavBar {
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.HomeNavBarTab {
  padding: 10px 15px 10px 15px;
  color:#666;
  display: block;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
  position: relative;
}
.HomeNavBarTab:hover {
  background-color: #e4e4e4;
  
}

.HomeNavBarTabActive {
  padding: 10px 15px 10px 15px;
  color:#000;
  display: block;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
  position: relative;
}

.HomeNavBarTabActive:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px; 
  width: 20px;
  height: 2px; 
  background-color: #000; 
  margin-left: -10px;
  margin-top: -10px;
}

.HomeNavBarTabActive:hover {
  background-color: #e4e4e4;
  
}

.HomeNavBarTabSignOutBtn {
  margin-right: 20px;
}
