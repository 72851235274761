.statsContainer {
    background-color: #fff;
    max-height: calc(100vh - 70px);
    min-height: calc(100vh - 70px);
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}

.statsContentContainer {
    width: 90%;
    position: relative;
}

.statsHeaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px;
    width: 900px;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    margin: auto;
    margin-top: 20px;
    position: relative;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);

}

.statsHeaderTopContainer {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    
}

.statsHeaderTopFilterText {
    display: block;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 7px 15px 7px 15px;
    color: #999;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}

.statsHeaderTopFilterTextSelected {
    display: block;
    border: 1px solid #e7e7e7;
    background-color: #272727;
    border-radius: 20px;
    padding: 7px 15px 7px 15px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}

.statsHeaderBottomContainer {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

.statsHeaderStatContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.statsHeaderStatValue {
    display: block;
    cursor: pointer;
    font-weight: 600;
    color:#272727;
    font-size: 18px;
}

.statsHeaderStatTitle {
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #888;
    padding-top: 10px;
}

.statsFloatingDateSelectorButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 60px;
    width: 60px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 65px;
    right: -120px;
    background-color: #272727;
    color: white;
    cursor: pointer;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
    transition: all 0.5s;
}

.statsFloatingDateSelectorButton:hover {
    background-color: #3f3f3f;

}

.statsContentRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 20px
}

.statsCard {
    min-width: 40vw;
    max-width: 40vw;
    min-height: 400px;
    max-height: 400px;
    padding: 30px;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    margin-top: 60px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);
    overflow: hidden;
}

.statsCardTitle {
    display: block;
    font-size: 24px;
    font-weight: 400;
    color: #272727;
    margin-bottom: 20px;
}

.statsChartContainer {
    min-width: 40vw;
    max-width: 40vw;
    min-height: 300px;
    max-height: 400px;
    padding: 0px;
    border-radius: 20px;
    margin-top: 40px;
}

.statsCardTableContainer {
    overflow-y: auto;
    display: block;
    max-height: 350px;
}

.statsCardTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0px;
}

.statsCardTable > tbody{
    max-height: 200px;
    overflow: auto;
}

.statsCardTable > tbody > tr > td {
    border-bottom: 1px solid #e7e7e7;
    padding: 20px 0px 20px 0px;
}



.statsDateSelectorBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    left: 0px;
}

.statsDateSelectorCard {
    width: 700px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statsDateSelectorsContainer {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.statsDateSelectorSubmitButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-top: 20px;

}

.statsDateSelectorSubmitButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    padding: 7px 15px 7px 15px;
    background-color: #272727;
    color: white;
    cursor: pointer;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
    transition: all 0.5s;
    margin: 0px;
}