

.FullAutoStep3Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: slideInFromLeft 0.3s ease-out;

}

.FullAutoStep3ContainerHidden {
    display: none;
    animation: slideOutToRight 0.3s ease-out;

}

.FullAutoStep3Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 500px;
    height: 100%;
}

.FullAutoStep3LeftStepNumber {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep3LeftTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 38px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.FullAutoStep3LeftDescription {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #666;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;

}

.FullAutoStep3LeftDescriptionText {
    display: block;
    color: #666;
    font-weight: 400;
    font-size: 16px;
    margin-top: 0px;
    margin-left: 30px;
}

.FullAutoStep3Middle {
    width: 550px;
    max-height: calc(100vh - 265px);
    min-height: calc(100vh - 265px);
    overflow: auto;
    overflow: -moz-scrollbars-none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.FullAutoStep3MiddleTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep3MiddleSubTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.FullAutoStep3MiddleAccountContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    padding-top: 33vh;
    padding-bottom: 30px;

}

.FullAutoStep3MiddleAccountSearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 25px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 15px;
    outline: 0px solid #000;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    transition: all 0.3s ease;
}

.FullAutoStep3MiddleAccount {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 60px;
    padding-left: 20px;
    margin-top: 10px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.FullAutoStep3MiddleAccountLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: 80%

}

.FullAutoStep3MiddleAccountName {
    display: block;
    color: #222;
    font-weight: 400;
    font-size: 16px;
}

.FullAutoStep3MiddleAccountQuota {
    display: block;
    color: #888;
    font-weight: 400;
    font-size: 14px;

}

.FullAutoStep3MiddleAccountRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    color: #1dca23;
    background-color: #5be78033;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0px 9px 9px 0px;
    font-size: 14px;
}

.FullAutoStep3MiddleAccountSelectedCheck {
    position: absolute;
    right: -50px;
    z-index: 20;
}

.FullAutoStep3MiddleAccountHiddenCheck {
    display: none;
}

.FullAutoStep3MiddleAccountBottomProgressParent {
    position: absolute;
    bottom: 0;
    left: 0px;
    display: block;
    width: 100%;
    height: 3px;
    background-color: #1ec6784d;

}

.FullAutoStep3MiddleAccountBottomProgressChild {
    display: block;
    width: 50%;
    min-height: 3px;
    border-radius: 15px;
    background-color: #1ec678;
    transition: all 0.3s ease;
}




.FullAutoStep3Right {
    display: block;

}

