

.FullAutoStep5Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: slideInFromLeft 0.3s ease-out;

}

.FullAutoStep5ContainerHidden {
    display: none;
    animation: slideOutToRight 0.3s ease-out;

}

.FullAutoStep5Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 500px;
    height: 100%;
}

.FullAutoStep5LeftStepNumber {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep5LeftTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 38px;
    margin-top: 10px;
}

.FullAutoStep5Middle {
    width: 525px;
    max-height: calc(100vh - 265px);
    min-height: calc(100vh - 265px);
    overflow: auto;
    overflow: -moz-scrollbars-none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.FullAutoStep5MiddleTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep5MiddleSubTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.FullAutoStep5MiddleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 30px;

}

.FullAutoStep5RowHeaderText {
    display: block;
    text-align: left;
    color: #1ec678;
    font-weight: 500;
    font-size: 30px;
    margin: 100px 0px 10px 0px;
    width: calc(400px + 20px);
}

.FullAutoStep5MailingCard {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 400px;
    height: 60px;
    padding-left: 20px;
    margin-top: 10px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;

}

.FullAutoStep5MiddleMailingLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 60px;
}

.FullAutoStep5MailingCardName {
    display: block;
    color: #222;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 5px;
}

.FullAutoStep5MailingCardRange {
    display: block;
    color: #888;
    font-weight: 400;
    font-size: 14px;

}

.FullAutoStep5MiddleMailingRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    color: #1dca23;
    background-color: #5be78033;
    font-weight: 600;
    border-radius: 0px 9px 9px 0px;
    font-size: 14px;

}

.FullAutoStep5MiddleMailingRightText {
    display: block;
    color: #1ec678;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 5px;

}

.FullAutoStep5MiddleAccountBottomProgressParent {
    position: absolute;
    bottom: 0;
    left: 0px;
    display: block;
    width: 100%;
    height: 5px;
    background-color: #1ec6784d;

}

.FullAutoStep5MiddleAccountBottomProgressChild {
    display: block;
    width: 50%;
    min-height: 5px;
    border-radius: 15px;
    background-color: #1ec678;
    transition: all 0.3s ease;
}













@keyframes slideInFromLeft {
    from {
        transform: translateX(-30%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(30%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-30%);
        opacity: 0;
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(30%);
        opacity: 0;
    }
}

@keyframes fadeInZoomIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOutZoomOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.5);
    }
}