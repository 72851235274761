

.FullAutoStep2Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: slideInFromLeft 0.3s ease-out;

}

.FullAutoStep2ContainerHidden {
    display: none;
    animation: slideOutToRight 0.3s ease-out;

}

.FullAutoStep2Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 500px;
    height: 100%;
}

.FullAutoStep2LeftStepNumber {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep2LeftTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 38px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.FullAutoStep2LeftDescription {
    display: block;
    color: #666;
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;

}

.FullAutoStep2Middle {
    width: 550px;
    max-height: calc(100vh - 265px);
    min-height: calc(100vh - 265px);
    overflow: auto;
    overflow: -moz-scrollbars-none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.FullAutoStep2MiddleTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep2MiddleSubTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.FullAutoStep2MiddleAccountContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    padding-top: 33vh;
    padding-bottom: 30px;

}

.FullAutoStep2MiddleAccountSearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 25px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 15px;
    outline: 0px solid #000;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    transition: all 0.3s ease;
}

.FullAutoStep2MiddleAccount {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 60px;
    padding-left: 20px;
    margin-top: 10px;
    border-radius: 15px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.FullAutoStep2MiddleAccountLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    height: 80%

}

.FullAutoStep2MiddleAccountName {
    display: block;
    color: #222;
    font-weight: 400;
    font-size: 16px;
}

.FullAutoStep2MiddleAccountQuota {
    display: block;
    color: #888;
    font-weight: 400;
    font-size: 14px;

}

.FullAutoStep2MiddleAccountRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 100%;
    color: #1dca23;
    background-color: #5be78033;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0px 13px 13px 0px;
    font-size: 14px;
}

.FullAutoStep2MiddleAccountSelectedCheck {
    position: absolute;
    right: -50px;
    z-index: 20;
}

.FullAutoStep2MiddleAccountHiddenCheck {
    display: none;
}







.FullAutoStep2Right {
    display: block;

}

