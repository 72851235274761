


.ChatGPTContainer {
    background-color: #fff;
    max-height: calc(100vh - 63px);
    min-height: calc(100vh - 63px);
    min-width: 100vw;
    display: flex;
    flex-direction: row;
}

.ChatGPTChatsContainer {
    min-width: 350px;
    max-width: 350px;
    border-right: 1px solid #e7e7e7;
    max-height: calc(100vh - 63px);
    overflow: auto;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.ChatGPTNewChat {
    margin: 20px;
    padding: 15px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    display: block;
    text-align: center;
    background-color: #272727;
    color: #fff;
    width: 80%;
    

}

.ChatGPTNewChat:hover {
    cursor: pointer;
    background-color: #5d5d5d;
    transition: background-color 0.3s;

}

.ChatGPTSubIDInputWrapper {
    position: relative;
    padding-bottom: 10px;
    margin-right: 20px;
}

.ChatGPTSubIDInputName {
    display: block;
    padding: 0px 5px 0px 5px;
    position: absolute;
    left: 5px;
    top: -5px;
    background-color: #fff;
    font-size: 10px;
    color: #666;
    
}

.ChatGPTSubIDInput {
    border: 1px solid #e5e5e5;
    min-height: 30px;
    border-radius: 10px;
    color: #000;
    font-size: 14px;
    justify-self: center;
    display: block;
    margin-top: 0px;
    padding-left: 10px;
    width: calc(100% + 7px);
    padding-top: 8px;

}

.ChatGPTFilterNowButton {
    display: block;
    text-align: center;
    cursor: pointer;
    user-select: none;
    border: 1px solid #e7e7e7;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #444;
}

.ChatGPTBatchFilter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    border: 1px solid #e7e7e7;
    width: 100%;
    padding: 10px 0px 10px 0px;
    border-radius: 10px;
    color: #444;
    cursor: pointer;
    user-select: none;
}

.ChatGPTChat {
    margin: 5px;
    padding: 0px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.5s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    width: 90%;
}

.SelectedChatGPTChat {
    margin: 5px;
    padding: 0px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    background-color: #e3e3e3;
    font-weight: 700;
    border: 1px solid #e7e7e7;
    font-size: 15px;
    width: 90%;

}

.ChatGPTChat:hover {
    cursor: pointer;
    background-color: #f3f3f3;
    transition: background-color 0.3s, box-shadow 0.5s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);

}

.ChatGPTChatText {
    padding: 15px;
}

.ChatGPTChatRenameInputContainer {
    padding: 10px;
    background-color: #e3e3e3;
    border-radius: 30px;

}

.ChatGPTChatRenameInput {
    padding: 5px;
    font-size: 16px;
    border: 1px solid #000;
    border-radius: 30px;
    background-color: #f2f2f2;
    width: fit-content;
    font-weight: 700;
    padding-left: 10px;
}

.ChatGPTChatRenameInputDescription {
    font-size: 10px;
    display: block;
    text-align: center;
    padding-top: 3px;
    color: #999999;
    font-weight: 400;
}

.ChatGPTChat .ChatGPTChatTrash {
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Prevents interaction when hidden */
}

.ChatGPTChat:hover .ChatGPTChatTrash {
    opacity: 1;
    pointer-events:all; /* Prevents interaction when hidden */

}

.ChatGPTChatTrash {
    background-color: rgb(224, 224, 224);
    padding: 5px;
    margin: 10px;
    border-radius: 20px;
}

.ChatGPTMailingCard {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 300px;
    height: 60px;
    padding-left: 20px;
    margin-top: 10px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;

}

.ChatGPTMiddleMailingLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 60px;
}

.ChatGPTMailingCardName {
    display: block;
    color: #222;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 5px;
}

.ChatGPTMailingCardRange {
    display: block;
    color: #888;
    font-weight: 400;
    font-size: 14px;

}

.ChatGPTMiddleMailingRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 100%;
    color: #1dca23;
    /* background-color: #5be78033; */
    background-color: transparent;
    font-weight: 600;
    border-radius: 0px 9px 9px 0px;
    font-size: 14px;
    color: #ececec

}


.ChatGPTMiddleMailingRightText {
    display: block;
    color: #1ec678;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 5px;

}

.ChatGPTMiddleAccountBottomProgressParent {
    position: absolute;
    bottom: 0;
    left: 0px;
    display: block;
    width: 100%;
    height: 5px;
    background-color: #1ec6784d;

}

.ChatGPTMiddleAccountBottomProgressChild {
    display: block;
    width: 50%;
    min-height: 5px;
    border-radius: 15px;
    background-color: #1ec678;
    transition: all 0.3s ease;
}

.ChatGPTRightContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.ChatGPTNewCreativeMode {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 15px 30px 15px 30px;
    border-radius: 45px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.5s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    background-color: #272727;
    color: #fff;
    margin-top: 30px;
    user-select: none;

}

.ChatGPTNewCreativeContainer {
    background-color: #fff;
    margin-top: 30px;
    min-width: 500px;
    max-width: 500px;
    border: 1px solid #e7e7e7;
    backdrop-filter: blur(10px); /* Apply a blur effect to the background */
    background-color: rgba(255, 255, 255, 0.5); /* Add some transparency to the element */
    padding: 0px;
    border-radius: 30px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);


}

.ChatGPTNewCreativeContainerHeader {
    border-bottom: 1px solid #e7e7e7;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px 0px 15px;
}

.ChatGPTNewCreativeTitle {
    font-size: 16px;
    display: block;
    text-align: center;
    line-height: 50px;
    width: 100%;
}

.ChatGPTNewCreativeHeaderSettingsIcon {
    border-radius: 50%;
    min-width: 35px;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #a7a7a7;
    transition: all 1.0s;
    cursor: pointer;
    border: 1px solid #e7e7e7
}

.ChatGPTNewCreativeHeaderSettingsIcon:hover {
    background-color: #e7e7e7;
    transform: rotate(45deg);
}

.ChatGPTNewCreativeContainerSubContainer {
    padding: 20px 20px 20px 30px;
}


.ChatGPTNewPromptButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.ChatGPTNewPromptButton {
    display: flex;
    padding: 5px 15px 5px 15px;
    margin: 0px 10px 10px 10px;
    border-radius: 10px;
    color: #777;
    cursor: pointer;
    transition: all 0.5s;
}

.ChatGPTNewPromptButton:hover {
    background-color: #f4f4f4;
}


.ChatGPTNewCreativeRow {
    display: flex;
    flex-direction: row;
}

.ChatGPTNewCreativeInputWrapper {
    position: relative;
    margin: 10px 10px 10px 5px;
    flex: 1;
}

.ChatGPTNewCreativeInputName {
    display: block;
    padding: 0px 5px 0px 5px;
    position: absolute;
    left: 5px;
    top: 7px;
    background-color: #fff;
    font-size: 10px;
    color: #666;
    
}

.ChatGPTNewCreativeInput {
    border: 1px solid #e5e5e5;
    min-height: 40px;
    border-radius: 10px;
    color: #000;
    font-size: 14px;
    justify-self: center;
    display: block;
    margin-top: 0px;
    padding-left: 10px;
    width: 415px;
    padding-top: 8px;

}


.ChatGPTNewCreativeInputPrompt {
    padding-top: 20px;
    border: 1px solid #e5e5e5;
    min-height: 120px;
    border-radius: 10px;
    color: #000;
    font-size: 14px;
    justify-self: center;
    display: block;
    margin-top: 0px;
    padding-left: 10px;
    width: calc(100% - 20px);
}

.ChatGPTFullAutoSpreadContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
}

.ChatGPTFullAutoSpreadOptionSelected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    background-color: #e5e5e5;
    margin: 5px;
    cursor: pointer;
    transition: all 0.3s;

}

.ChatGPTFullAutoSpreadOption {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    margin: 5px;
    cursor: pointer;
    transition: all 0.3s;

}

.ChatGPTSavedPromptsRowTitle {
    display: block;
    width: 100%;
    text-align: center;
    padding: 15px 0px 5px 0px;
    color: #666;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
}

.ChatGPTNewPromptSearchWrapper {
    position: relative;
    margin: 10px 10px 10px 5px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
}

.ChatGPTNewPromptSearchIcon {
    display: block;
    padding: 0px 10px 0px 10px;
    background-color: #fff;
    font-size: 10px;
    color: #666;  
    cursor: pointer;
}

.ChatGPTNewPromptSearch { 
    border: 0px;
    outline: 0px;
    border-radius: 40px;
    min-height: 40px;
    color: #000;
    font-size: 14px;
    justify-self: center;
    display: block;
    margin-top: 0px;
    padding-left: 4px;
    width: 415px;
    padding-top: 0px;

}

.ChatGPTSavedPromptsRow {
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap onto multiple lines */
    max-height: 200px; /* Adjust the maximum height as needed */
    overflow-y: auto; /* Add vertical scrolling if content exceeds the height */
    scrollbar-width: thin;
}

.ChatGPTNewCreativeSavePromptButton {
    line-height: 20px;
    margin-left: 10px;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: 13px;
    border-radius: 30px;
    padding: 5px 7px 5px 7px;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #ccc;
    white-space: nowrap;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    transition: all 0.5s;
    color: #bbb;
}

.ChatGPTNewCreativeSavePromptButton:hover {
    background-color: #f1f1f1; 
    border: 1px solid #aaa;
    color: #aaa;

}

.ChatGPTNewCreativeSavePromptFullAutoOptionButton {
    line-height: 20px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 13px;
    border-radius: 10px;
    border: 1px solid #ccc;
    color: #666;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
}

.ChatGPTNewCreativeSavePromptFullAutoOptionButtonTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px 10px 15px;

}

.ChatGPTNewCreativeSavePromptFullAutoOptionButtonPromptName {
    display: block;
}

.ChatGPTNewCreativeSavePromptFullAutoOptionButtonPromptEPM {
    display: block;
    font-weight: 600;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.ChatGPTListPercentInput {
    border: 1px solid #fff;
    border-radius: 5px;
    color: #666;
    font-size: 17px;
    justify-self: center;
    display: block;
    margin-top: 0px;
    padding-left: 3px;
    width: 45px;
    padding-top: 0px;
    outline: none;
    text-align: end;

}

.ChatGPTNewCreativeSavePromptOptionButton {
    line-height: 20px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: 13px;
    border-radius: 30px;
    border: 1px solid #ccc;
    color: #666;
    cursor: pointer;
    white-space: nowrap;
    user-select: none;
}

.ChatGPTNewCreativeSavePromptOptionButtonSelected {
    line-height: 20px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: 13px;
    border-radius: 30px;
    border: 1px solid #000000;
    color: #000000;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 600;
    user-select: none;

}

.ChatGPTNewCreativeSavePromptOptionButtonTextFullAutoSelected {
    display: none;

}

.ChatGPTNewCreativeSavePromptOptionButtonText {
    padding: 5px 15px 5px 15px;

}

.ChatGPTNewCreativeSavePromptOptionButton .ChatGPTPromptTrash {
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: all; /* Prevents interaction when hidden */
}

.ChatGPTNewCreativeSavePromptOptionButton:hover .ChatGPTPromptTrash {
    opacity: 1;
    pointer-events:all; /* Prevents interaction when hidden */

}

.ChatGPTPromptTrash {
    background-color: rgb(232, 232, 232);
    padding: 5px;
    margin: 5px;
    border-radius: 20px;
}

.ChatGPTNewCreativeSavePromptButtonEmpty {
    min-height: 32px;
    display: block;
}

.ChatGPTNewBatchSettingsDropdownButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;
    margin-top: 15px;
    transition: all 0.5s;
}

.ChatGPTNewBatchSettingsDropdownButtonRow:hover {
    background-color: #f4f4f4;

}

.ChatGPTNewBatchSettingsDropdownClosed {
    display: none;
    max-height: 0px;
    opacity: 0px;
    transition: all 0.5s;
}

.ChatGPTNewCreativeInputDropdown {
    padding-top: 10px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    min-height: 50px;
    color: #000;
    font-size: 14px;
    justify-self: center;
    display: block;
    margin-top: 20px;
    padding-left: 5px;
    width: calc(100% - 5px);
}

.ChatGPTNewPromptSharingSection {

}

.ChatGPTNewPromptSharingUserRowSelected {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid #000;
    margin-bottom: 5px;
}

.ChatGPTNewPromptSharingUserRowSelected:hover {
    background-color: #e7e7e7;
}

.ChatGPTNewPromptSharingUserRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    margin-bottom: 5px;
}

.ChatGPTNewPromptSharingUserRow:hover {
    background-color: #e7e7e7;
}

.ChatGPTNewCreativeRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 25px);
    margin: auto;
    padding-top: 10px;
}

.ChatGPTNewCreativeRowText {
    display: block;
    cursor: pointer;
}

.ChatGPTFullAutoQuotaInformationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    margin-top: 20px;
    font-size: 14px;
    color: #666;

}

.ChatGPTFullAutoQuotaInformationRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    border: 0px solid #e7e7e7;
    margin: 5px;
    width: 95%;
}

.ChatGPTFullAutoQuotaInformationName {
    display: block;
}

.ChatGPTFullAutoQuotaInformationValue {
    display: block;
    font-weight: 600;
}

.ChatGPTGenerateButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.ChatGPTGenerateButton {
    display: block;
    width: fit-content;
    color: #fff;
    background-color: rgb(44, 44, 44);
    padding: 15px 40px 15px 40px;
    border-radius: 30px;
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
}

.ChatGPTSavePrompt {


}

.ChatGPTCreativesRowContainer { 
    width: 100%;
    max-width: calc(100vw - 350px);
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    background-color: #eaeaea;
    border: 1px solid #e7e7e7;
    min-height: 30px;

}

.ChatGPTCreativesRowItemSelected {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid #e7e7e700;
    width: 250px;
    min-width: 150px;
    height: 30px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    color: #868686;
    border-radius: 10px 10px 0px 0px;
    transition: background-color 0.3s;
    overflow: hidden;
}

.ChatGPTCreativesRowItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0);
    border: 1px solid #ffffff01;
    width: 250px;
    min-width: 150px;
    height: 30px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    color: #aaaaaa;
    transition: background-color 0.3s;
    overflow: hidden;

}

.ChatGPTCodeEditorContainer {
    display: flex;
    position: relative;
    z-index: 0;
}

.ChatGPTRevisionSettingsContainer {
    margin-top: 150px;
    min-height: 300px;
    min-width: 500px;
    width: 500px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    border-radius: 30px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: auto;
    outline: none;
}

.ChatGPTGeneratingInidicatorContainer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border: 0px solid #e7e7e7;
    padding: 10px 15px 10px 16px;
    border-radius: 0px;
    justify-content: center; /* Center children horizontally */
    position: absolute;
    left: -16px;
    width: calc((100% / 2) + 5px);
    bottom: -10px;
    z-index: 800;

}

.ChatGPTGeneratingInidicatorBorderRadius {
    border-radius: 20px;
    width: 100%;
    border: 0px solid #e7e7e7;
    color: #444;
}

.ChatGPTGeneratingInidicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #d0d0d0;
    padding: 15px 15px 15px 16px;
    background-color: #ffffff;
    border-radius: 0px;
    justify-content: center; /* Center children horizontally */
    backdrop-filter: blur(10px); /* Apply blur after gradient */
    font-weight: 600;
    font-size: 18px;
    height: 40px
}

.ChatGPTCustomPromptContainer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    border: 0px solid #e7e7e7;
    padding: 10px 15px 10px 16px;
    border-radius: 0px;
    justify-content: center; /* Center children horizontally */
    position: absolute;
    left: -1px;
    width: calc((100% / 2) - 26px);
    bottom: 0px;
    z-index: 8;
    backdrop-filter: blur(10px); /* Apply blur after gradient */

}




.ChatGPTCustomPromptInputContainer {
    min-height: 52px;
    min-width: 600px;
    flex: 1;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.694);
    color: #444;
    outline: none;
    border: 1px solid #dddddd;
    transition: border 1.0s, box-shadow 1.0s;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
}
.ChatGPTCustomPromptInputContainer:hover {
    border: 1px solid #939393;
}
.ChatGPTCustomPromptInputContainer:focus {
    border: 1px solid #4d4d4d;
    box-shadow: 0px 0px 20px rgba(65, 65, 65, 0.2);
}

.ChatGPTCustomPromptInput {
    outline: none;
    border: 0px solid #fff;
    width: 100%;
    background-color: #ffffff00;
    font-size: 17px;
}
.ChatGPTCustomPromptInput::placeholder {
    color: #868686
}

.ChatGPTCustomPromptButton {
    height: 32px;
    width: 34px;
    color:#868686;
    display: block;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    margin: 0px 10px 0px 5px;
    position: relative;
    background-color: #2b2b2b;
    font-weight: 600;
    transition: background-color 0.5s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .ChatGPTCustomPromptButtonDisabled {
    height: 32px;
    width: 34px;
    color:#868686;
    display: block;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    margin: 0px 10px 0px 5px;
    position: relative;
    background-color: #e3e3e3;
    font-weight: 600;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .ChatGPTCustomPromptIconButton {
    padding: 12px 12px 8px 12px;
    color:#fff;
    display: block;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    margin: 0px 5px 0px 5px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);
    font-weight: 600;
    transition: background-color 0.3s;
    border: 1px solid #c9c9c9;

  }
  
  .ChatGPTCustomPromptIconButton:hover {
    background-color: rgba(255, 255, 255, 0.4);
    
  }

.ChatGPTCodeContainer {
    padding-top: 0px;
    min-width: calc(50vw - 170px);
    max-width: calc(50vw - 170px);
    background-color: #ffffff;
    color: #fff;
    min-height: calc(100vh - 155px);
    max-height: calc(100vh - 155px);
    border-right: 1px solid #e7e7e7;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    position: relative;


}

body .ace_scrollbar {
    display: none;
}

.ChatGPTEditorToolbarContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.ChatGPTEditorToolbarButton {
    height: 50px;
    width: 50px;
    color:#444;
    display: block;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    cursor: pointer;
    margin: 0px 5px 0px 5px;
    background-color: rgb(255, 255, 255);
    font-weight: 600;
    transition: background-color 0.3s;
    z-index: 20;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border: 1px solid #e7e7e7

}
  
.ChatGPTEditorToolbarButton:hover {
    background-color: #e7e7e7;
    
}


.ChatGPTPreviewContainer {
    width: calc(50vw - 180px);
    border: 0px;
}

.ChatGPTUpdatePreviewButton {
    background-color: #fff;
    position: absolute;
    bottom: 90px;
    right: 20px;
    padding: 15px;
    border-radius: 30px;
    color: #2b5fe2;
    font-weight: 700;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}


.ChatGPTFloatingBottomRow {
    position: absolute;
    bottom: -1px;
    right: 0px; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #d6d6d6; /* 2b5fe2 */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0);
    border-top: 1px solid #e7e7e7;
    border-radius: 0px;
    padding: 10px;
    min-width: calc(100vw - 370px);
    z-index: 0;

}

.ChatGPTFloatingBottomRowAIBadge {
    padding: 10px 0px 10px 15px ;
    margin-right: 5px;
    font-weight: 900;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.00);
    color: #666;
}

.ChatGPTFloatingBottomRowButtonText {
    padding: 10px 10px 10px 10px ;
    font-size: 15px;

}

.ChatGPTFloatingBottomRowSettingsIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ChatGPTFloatingBottomRowSettingsIcon {
    padding: 8px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.ChatGPTFloatingBottomRowSettingsIcon:hover {
    background-color: rgba(0, 0, 0, 0.289);
}

.ChatGPTFloatingBottomRowButton {
    padding: 0px 5px 0px 5px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
    color: #666;
    border: 1px solid #cccccc;

}

.ChatGPTFloatingBottomRowButton:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.85);
    transition: background-color 0.3s;

}

.ChatGPTFloatingBottomRowButtonDisabled {
    padding: 0px 5px 0px 5px;
    border-radius: 25px;
    cursor: not-allowed;
    transition: background-color 0.3s;
    display: flex;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
    color: #666;
    border: 1px solid #cccccc;

}

.ChatGPTFloatingBottomRowIconButton {
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
    color: #666;
    border: 1px solid #cccccc;

}

.ChatGPTFloatingBottomRowIconButton:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.85);
    transition: background-color 0.3s;

}

.ChatGPTFloatingBottomRowIconAndTextButton {
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
    color: #666;
    border: 1px solid #cccccc;

}

.ChatGPTFloatingBottomRowIconAndTextButton:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.85);
    transition: background-color 0.3s;

}

.ChatGPTFloatingBottomRowRevision {
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    text-align: center;
    color: #868686;
    padding: 10px 10px 10px 10px ;

}

.ChatGPTFloatingBottomRowSpacer {
    min-width: 10px;
}

.ChatGPTFloatingBottomRowOptionsButtonContainer {
    position: relative;
}


.ChatGPTFloatingBottomRowIconButtonSettings {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.65);
    color: #666;
    border: 1px solid #ccc;

}

.ChatGPTFloatingBottomRowIconButtonSettings:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.85);
    transition: background-color 0.3s;

}

.ChatGPTFloatingBottomRowOptionsContainer {
    position: absolute;
    top: -100px;
    left: -320px;
    background-color: #fff;
    width: 370px;
    height: 30px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);

}

.ChatGPTFloatingBottomRowOptionsContainerRow {
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    cursor: default;
}

.ChatGPTFloatingBottomRowOptionsContainerRowText {
    font-size: 18px;
    display: block;
    margin-left: 70px;
    user-select: none;
}


.ChatGPTPushToMailerContainer {
    margin-top: 150px;
    min-height: 100%;
    max-height: 100%;;
    width: 100%;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: auto;
    outline: none;
    overflow-y: auto;

}

.ChatGPTPushToMailerContainerHeader {
    border-bottom: 1px solid #e7e7e7;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.ChatGPTPushToMailerHeaderLeft {
    width: 60px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}

.ChatGPTPushToMailerTitle {
    font-size: 16px;
    display: block;
    text-align: center;
    line-height: 50px;
}

.ChatGPTPushToMailerHeaderRight {
    width: 80px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.ChatGPTPushToMailerHeaderRightArrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 30px;
    height: 35px;
    width: 35px;
    transition: background-color 0.5s;
}

.ChatGPTPushToMailerHeaderRightArrow:hover {
    background-color: #e7e7e7;
    transition: background-color 0.5s;

}


.ChatGPTMailerStepsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}

.ChatGPTMailerStepsSubContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 10px 20px 10px 0px;
    border-radius: 20px;
    width: 800px;

}

.ChatGPTMailerStepNumber {
    display: block;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #ffffff;
    font-size: 25px;
    color: #089e21;
    border-radius: 15px;
    margin: 0px 20px 0px 20px;
    border: 2px solid #089e21;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;

}

.ChatGPTMailerStepNumberSelected {
    display: block;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #ffffff;
    font-size: 25px;
    color: #089e21;
    border-radius: 15px;
    margin: 0px 20px 0px 20px;
    border: 2px solid #089e21;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;
}

.ChatGPTMailerStepNumberCompleted {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    text-align: center;
    background-color: #089e21;
    font-size: 25px;
    color: white;
    border-radius: 15px;
    margin: 0px 20px 0px 20px;
    border: 2px solid #089e21;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;

}

.ChatGPTMailerStepName {
    display: block;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}


.ChatGPTCreateMailingMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.ChatGPTCreateMailingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    height: 100%;
}

.ChatGPTCreateMailingColumn {
    margin: 30px;
    border: 1px solid #089e21;
    padding: 30px;
    width: fit-content;
    border-radius: 20px;
    position: relative;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    transition: all 0.5s;
}

.ChatGPTCreateMailingColumnCheck {
    position: absolute;
    left: 50%; 
    top: -22px;
    transform: translateX(-50%); 
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    transition: all 0.5s;
    border: 2px solid #089e21;
    color: #089e21;
    font-weight: 600;

}

.ChatGPTCreateMailingColumnLabel {
    position: absolute;
    left: 50%; 
    top: -22px;
    transform: translateX(-50%); 
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    transition: all 0.5s;
    border: 2px solid #e7e7e7;
    color: #888;
    font-weight: 600;
}

.ChatGPTEmptyListRangeMessage {
    text-transform: uppercase;
    font-weight: 600;
    color: #999;
    font-size: 18px;
}

.ChatGPTLastSendRangeText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;
}

.ChatGPTCreateMailingSizeInputRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ChatGPTCreateMailingListRangeInputBoxContainer {
    margin: 10px;
}

.ChatGPTCreateMailingListRangeInputBox {
    width: 175px
}

.ChatGPTCreateMailingListRangeInputLock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    border: 1px solid #e7e7e7;
    border-radius: 40px;
    padding: 7px;
    color: #888;
    transition: all 0.5s;
}

.ChatGPTCreateMailingListRangeInputLock:hover {
    background-color: #e7e7e7;
}

.ChatGPTSuppressionSelectorsContainerHide {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s;
}

.ChatGPTShowSuppressionButton {
    border: 1px solid #e7e7e7;
    padding: 10px;
    border-radius: 15px;
    background-color: #e7e7e7;
    text-align: center;
    color: #666;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
    transition: all 0.5s;

}

.ChatGPTSuppressionSelectorsContainerShow {
    transition: all 0.5s;
}

.ChatGPTCreateMailingSwitchContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px 0px 10px;
}

.ChatGPTCreateMailingSwitchText {
    display: block;
    margin-left: 10px;
}

.ChatGPTCreateMailingAccountSelectorContainer {

}

.ChatGPTCreateMailingListSelector {
    display: block;
    width: calc(100% - 14px);
    height: 40px;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #e7e7e7;
    color: #444;
    transition: border 0.5s, color 0.5s;
}

.ChatGPTCreateMailingDateSelectorContainer {
    margin-top: 20px;
}

.ChatGPTCreateMailingDateSet25HoursApart {
    border: 1px solid #e7e7e7;
    padding: 10px;
    border-radius: 80px;
    background-color: #fff;
    text-align: center;
    color: #666;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
    transition: all 0.5s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0px;
    right: 15px;  
    transition: all 0.5s;
}

.ChatGPTCreateMailingDateSet25HoursApart:hover {
    background-color: #e7e7e7;
}

.ChatGPTRandomizeRadioButtons {
    display: flex;
    flex-direction: column;
    margin: 15px;
}

.ChatGPTRandomizeRadioButtons label {
    display: block;
    margin-top: 15px;
}













.ChatGPTCreateMailingContainer2 {
    display: flex;
    flex-direction: column;
    margin-top: 0px;

}

.ChatGPTCreateMailingRow2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 30px;
    width: 1100px;
}

.ChatGPTMailerStepLeft {
    margin-right: 100px;
    padding-top: 20px;
}

.ChatGPTMailerStepLeftTitle {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: #222;
}

.ChatGPTMailerStepLeftDescription {
    display: block;
    font-size: 14px;
    padding-top: 5px;
    color: #666

}

.ChatGPTCreateMailingColumn2 {
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .05);
    padding: 20px;
    border-radius: 15px;
    width: 600px;
    position: relative;
    transition: all 0.5s;
    overflow: hidden;
}

.ChatGPTCreateMailingColumn2MoreQuotaInfoIcon {
    position: absolute;
    top: 3px;
    left: 3px;
    color: #999;
    padding: 5px;
    border-radius: 30px;
    border: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ChatGPTCreateMailingColumn2CustomizeQuotaIcon {
    position: absolute;
    top: 3px;
    right: 3px;
    color: #999;
    padding: 5px;
    border-radius: 30px;
    border: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.ChatGPTShowSuppressionButton2 {
    padding: -5px 0px 10px 0px;
    border-radius: 15px;
    text-align: right;
    color: #000;
    cursor: pointer;
    user-select: none;
    margin-top: 10px;
    transition: all 0.5s;
    position: absolute;
    top: 0px;
    right: 15px;
    font-size: 12px;

}

.ChatGPTListRangeCalculatingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}

.ChatGPTListRangeCalculatingText {

}

.ChatGPTCalculateListButton {
    display: block;
    width: fit-content;
    color: white;
    background-color: #272727;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;    
    margin: auto;
    cursor: pointer;
    user-select: none;
}

.ChatGPTRandomizeButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #e7e7e7;
    color: #666;
    margin: 0px 20px 20px 20px;
    border-radius: 20px;
    background-color: #e7e7e7;
    transition: all 0.5s;

}

.ChatGPTRandomizeButtonSelected {
    display: block;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .05);
    cursor: pointer;
    padding: 5px;
    flex: 1;
    text-align: center;
    border-radius: 20px;
    background-color: #fff;
    transition: all 0.5s;
    color: #444;
    font-weight: 600;
}

.ChatGPTRandomizeButton {
    display: block;
    cursor: pointer;
    padding: 5px;
    flex: 1;
    text-align: center;
    transition: all 0.5s;

}

.ChatGPTListRangeBar {
    height: 10px;
    background-color: #5be78033;
    border-radius: 20px;
    width: 640px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    transition: all 1.0s;
}

.ChatGPTListRangeBarActive {
    height: 10px;
    background-color: #1dca23;
    border-radius: 20px;
    transition: all 1.0s;

}

.ChatGPTLastSendText {
    display: block;
    margin-top: 2px;
    padding: 0px 0px 10px 0px;
    position: absolute;
    top: 0px;
    left: 10px;
    font-size: 12px;
    color: #666
}

.ChatGPTCustomSeedTestContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    border: 1px dashed #e7e7e7;
    margin: 80px 20px 40px 20px;

}

.ChatGPTCustomSeedTestInput {
    display: block;
    width: 300px;
    height: 40px;
    font-size: 16px;
    padding-left: 10px;
    margin-right: 20px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #e7e7e7;
    color: #444;
    transition: border 0.5s, color 0.5s;

}

.ChatGPTCustomSeedTestButton {
    display: block;
    text-align: center;
    width: 120px;
    color: white;
    background-color: #272727;
    padding: 10px 0px 10px 0px;
    border-radius: 10px;    
    cursor: pointer;
    user-select: none;

}

.ChatGPTSendMailButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 1100px;

}

.ChatGPTSendMailButton {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 15px 30px 15px 30px;
    background-color: #272727;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.5s;
}

.ChatGPTSendMailButton:hover {
    background-color: #4d4d4d;
}

.ChatGPTFullAutoFromsAndSubsSaveChangesButton {
    margin: auto;
    margin-bottom: 30px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 30px 15px 30px;
    background-color: #272727;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.5s;
    user-select: none;
}

.ChatGPTMailingCreatedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85vh;
}

.ChatGPTMailingCreatedText {
    font-size: 30px;
    color: #272727;
    display: block;
}

.ChatGPTMailingsButton {
    margin-top: 40px;
    margin-bottom: 100px;
    display: block;
    padding: 15px 30px 15px 30px;
    background-color: #272727;
    color: #fff;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.5s;    
}






.ChatGPTPushToMailerProgressParent {
    min-width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
}

.ChatGPTPushToMailerProgressChild {
    height: 2px;
    background-color: rgb(8, 158, 33);
    box-shadow: 0px 0px 2px rgba(8, 158, 33, 1);
    transition: width 1s
}

.ChatGPTPushToMailerContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}   

.ChatGPTPushToMailerContentLeft {
    padding: 20px;
    min-width: 51vw;
}

.ChatGPTPushToMailerContentLeftTitle {
    color: #aaaaaa;
    font-size: 18px;
    font-weight: 600;
    display: block;
    text-align: center;
    width: 100%;
    margin: 20px;
}

.ChatGPTPushToMailerContentLeftInputWrapper {
    position: relative;
    width: calc(80% - 14px);
    margin: 20px auto;

}

.ChatGPTPushToMailerContentLeftInputLabel {
    font-size: 12px;
    position: absolute;
    top: -8px;
    left: 6px;
    display: block;
    background-color: #fff;
    padding: 0px 5px 0px 5px;
    color: #444
}   

.ChatGPTPushToMailerContentLeftInput {
    display: block;
    width: calc(100% - 14px);
    height: 40px;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #e7e7e7;
    color: #444;
    transition: border 0.5s, color 0.5s;
}

.ChatGPTPushToMailerContentLeftInput:hover {
    border: 1px solid #8e8e8e;
}

.ChatGPTPushToMailerContentLeftInput:focus {
    border: 1px solid #272727;
    color: #000
}

.ChatGPTPushToMailerContentLeftSelect {
    display: block;
    width: 100%;
    height: 44px;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #e7e7e7;
    color: #444;
    margin: 10px auto;

}

.ChatGPTPushToMailerContentLeftSelect:hover {
    border: 1px solid #8e8e8e;
}

.ChatGPTPushToMailerContentLeftSelect:focus {
    border: 1px solid #272727;
    color: #000
}

.ChatGPTPushToMailerContentLeftSpamScoreTableContainer {
    min-height: 550px;
    max-height: 550px;
    overflow-y: auto;
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    width: 80%;
    margin: auto;
}
.ChatGPTPushToMailerContentLeftSpamScoreTable {

    color: #444;
    padding: 20px;

}

.ChatGPTPushToMailerContentLeftSpamScoreTable > thead {
    width: 80%;
    margin: auto;

    border-radius: 20px;
    height: 40px;
}

.ChatGPTPushToMailerEditorContainerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width: 45vw;
    max-width: 80%;
}

.ChatGPTPushToMailerEditorContainerHeaderRefreshButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 45px;
    border: 1px solid #e7e7e7;
    cursor: pointer;
    border-radius: 30px;
    color: #838282
}

.ChatGPTPushToMailerEditorContainer {
    padding-top: 0px;
    min-width: 45vw;
    max-width: 100%;
    background-color: #ffffff;
    color: #fff;
    min-height: 70vh;
    max-height: 70vh;
    border-right: 1px solid #e7e7e7;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border: 2px solid #e7e7e7;
    margin-right: 2vw;
}



.ChatGPTPushToMailerButton {
    display: block;
    width: fit-content;
    color: #fff;
    background-color: rgb(44, 44, 44);
    padding: 15px 40px 15px 40px;
    border-radius: 30px;
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
    transition: background-color 0.5s;
}

.ChatGPTPushToMailerButtonAlreadyPushed {
    display: block;
    width: fit-content;
    color: #fff;
    background-color: #089e21;
    padding: 15px 40px 15px 40px;
    border-radius: 30px;
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
    transition: background-color 0.5s;

}


.ChatGPTPushToMailerButtonAlreadyPushed:hover {
    background-color: rgb(7, 128, 27);


}




.ChatGPTFixAllContainer {
    margin-top: 150px;
    min-height: 800px;
    max-height: 800px;
    min-width: 600px;
    max-width: 600px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    border-radius: 30px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: auto;
    outline: none;
    overflow: auto;
}

.ChatGPTFixAllContainerHeader {
    border-bottom: 1px solid #e7e7e7;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px 0px 15px;
}

.ChatGPTFixAllContainerHeaderIcon {
    margin-right: 0px;
    height: 40px;
    width: 50px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #fff;
    transition: all 0.5s;
}

.ChatGPTFixAllContainerHeaderIcon:hover {
    border: 1px solid #e7e7e7;
    background-color: #e7e7e7;
}

.ChatGPTFixAllTitle {
    font-size: 16px;
    display: block;
    text-align: center;
    line-height: 50px;
    width: 100%;
}

.ChatGPTFixAllContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ChatGPTFixAllSettingRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 40px 5px 40px;
    border-bottom: 1px solid #e7e7e7;
}


.ChatGPTFixAllInputWrapper {
    position: relative;
    margin: 10px 10px 10px 10px;
}

.ChatGPTFixAllInputText {
    display: block;
    padding: 0px 5px 0px 5px;
    position: absolute;
    left: 5px;
    top: -5px;
    background-color: #fff;
    font-size: 11px;
    color: #666;
    
}

.ChatGPTFixAllInput {
    border: 1px solid #e5e5e5;
    min-height: 40px;
    border-radius: 10px;
    color: #000;
    font-size: 14px;
    justify-self: center;
    display: block;
    margin-top: 0px;
    padding-left: 10px;
    width: calc(100% - 20px);
    padding-top: 8px;

}

.ChatGPTFixAllRandomIconSelected {
    margin-right: 0px;
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #fff;
    background-color: #e7e7e7;
    transition: all 0.5s;
}

.ChatGPTFixAllRandomIcon {
    margin-right: 0px;
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #fff;
    transition: all 0.5s;
}

.ChatGPTFixAllRandomIcon:hover {
    border: 1px solid #e7e7e7;
    background-color: #e7e7e7;
}


.ChatGPTPotentialMailingIssueModal {
    margin-top: 150px;
    min-height: 300px;
    min-width: 900px;
    width: 900px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    border-radius: 30px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: auto;
    outline: none;
}

.ChatGPTMailingIssueTextContainer {
    border: 1px solid #e7e7e7;
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 5px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    font-size: 14px;
}

.ChatGPTMailingIssueText {
    display: block;
    text-align: center;
    padding: 5px;

}


.ChatGPTFullAutoCreateMailingsPopupContainer {
    max-height: 80vh;
    overflow: auto;
    padding: 20px;
}

.ChatGPTFullAutoCreateMailingsPopupTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

}

.ChatGPTFullAutoCreateMailingsPopupOfferRow {
    display: flex;
    flex-direction: row;
    justify-content: flex start;
    align-items: center;
    padding: 10px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

}

.ChatGPTFullAutoCreateMailingsPopupOfferName {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #444;
    width: 300px;
}

.ChatGPTFullAutoCreateMailingsPopupOfferListPercent {
    display: block;
    font-size: 14px;
    color: #666;
}

.ChatGPTFullAutoCreateMailingsPopupAccountRow {
   
    border-bottom: 1px solid #e7e7e7;
}



.ChatGPTFullAutoCreateMailingsPopupAccountName {
    font-size: 14px;
    color: #666;
    width: 300px;
}

.ChatGPTFullAutoCreateMailingsPopupAccountListStart {
    font-size: 14px;
    color: #666;
}

.ChatGPTFullAutoCreateMailingsPopupAccountListEnd {
    font-size: 14px;
    color: #666;
}

.ChatGPTFullAutoCreateMailingsPopupAccountQuota {
    font-size: 14px;
    color: #666;

}

.ChatGPTFullAutoCreateMailingsPopupAccountStartTime {
    font-size: 14px;
    color: #666;
}