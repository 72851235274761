

.FullAutoStep1Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: slideInFromLeft 0.3s ease-out;

}

.FullAutoStep1ContainerHidden {
    display: none;
    animation: slideOutToRight 0.3s ease-out;

}

.FullAutoStep1Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 500px;
    height: 100%;
}

.FullAutoStep1LeftStepNumber {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep1LeftTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 38px;
    margin-top: 10px;
}

.FullAutoStep1Middle {
    width: 425px;
    max-height: calc(100vh - 265px);
    min-height: calc(100vh - 265px);
    overflow: auto;
    overflow: -moz-scrollbars-none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.FullAutoStep1MiddleTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep1MiddleSubTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.FullAutoStep1MiddleMailingListContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    padding-top: 33vh;
    padding-bottom: 30px;

}

.FullAutoStep1MiddleMailingListSearch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 25px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 15px;
    outline: 0px solid #000;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    transition: all 0.3s ease;
}

.FullAutoStep1MiddleMailingListSelected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 25px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 15px;
    border: 2px solid #1dca23;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.FullAutoStep1MiddleMailingList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 25px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 15px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.FullAutoStep1MiddleMailingListName {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}



.FullAutoStep1Right {

}










@keyframes slideInFromLeft {
    from {
        transform: translateX(-30%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(30%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-30%);
        opacity: 0;
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(30%);
        opacity: 0;
    }
}

@keyframes fadeInZoomIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOutZoomOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.5);
    }
}