

.FullAutoStep4Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    animation: slideInFromLeft 0.3s ease-out;

}

.FullAutoStep4ContainerHidden {
    display: none;
    animation: slideOutToRight 0.3s ease-out;

}

.FullAutoStep4Left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 550px;
    height: 100%;
}

.FullAutoStep4LeftStepNumber {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep4LeftTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 38px;
    margin-top: 10px;
}

.FullAutoStep4Middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 825px;
    max-height: calc(100vh - 265px);
    min-height: calc(100vh - 265px);
    overflow: auto;
    overflow: -moz-scrollbars-none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.FullAutoStep4MiddleTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

.FullAutoStep4MiddleSubTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.FullAutoStep4MiddleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 30px;

}

.FullAutoStep4MiddleTable {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 0px;
}

.FullAutoStep4MiddleTableTopic {
    display: block;
    color: #444;
    font-weight: 600;
    font-size: 18px;
    line-height: 38px;
}

.FullAutoStep4MiddleTableOptionRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    margin-left: 20px;
}

.FullAutoStep4MiddleTableOptionSelected {
    display: block;
    color: #1dca23;
    background-color: #5be78033;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
    
}

.FullAutoStep4MiddleTableOption {
    display: block;
    color: #444;
    background-color: #e7e7e7;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
}

.FullAutoStep4TextInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 16px;
    padding: 10px;
    font-size: 20px;
    border-radius: 15px;
    outline: 0px solid #000;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    transition: all 0.3s ease;
}














@keyframes slideInFromLeft {
    from {
        transform: translateX(-30%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(30%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-30%);
        opacity: 0;
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(30%);
        opacity: 0;
    }
}

@keyframes fadeInZoomIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOutZoomOut {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.5);
    }
}