.loginContainer {
  min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #999999;
}

.loginContentContainer {
    backdrop-filter: blur(10px); /* Apply a blur effect to the background */
    background-color: rgba(255, 255, 255, 0.5); /* Add some transparency to the element */
    padding: 20px;
    width: 600px;
    height: 500px;
    border-radius: 20px;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginTitle {
    font-size: 23px;
    margin-bottom: 30px;
}

.toggle-container {
    display: flex;
    border: 3px solid #f0f0f0; /* Adjust the color to match your screenshot */
    border-radius: 23px; /* This gives the rounded corners */
    background-color: #f0f0f0;
  }
  
  .toggle-button {
    flex: 1;
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    border-radius: 23px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 150px;
  }
  .toggle-button:hover {
    background-color: #ffffff;
  }
  
  .toggle-button.active {
    background-color: #fff; /* Adjust active color to match your screenshot */
    border-radius: 20px; /* Maintain the rounded corners on active button */
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.05);

}
  
  /* Adjust font styling to match your screenshot */
  .toggle-button {
    font-size: 16px; /* Example size, adjust as needed */
    color: #333; /* Example color, adjust as needed */
  }
  

.emailField {
    backdrop-filter: blur(10px); /* Apply a blur effect to the background */
    background-color: rgba(255, 255, 255, 0.0);
    border: 1px solid #999;
    min-height: 40px;
    border-radius: 15px;
    width: 400px;
    padding: 5px;
    color: #666;
    font-size: 20px;
    justify-self: center;
    display: block;
    margin-top: 30px;
    padding-left: 20px;

}
.emailField:focus {
    border-color: #666;
    outline: none; /* Removes the default focus highlight */
}
.validEmailField {
    backdrop-filter: blur(10px); /* Apply a blur effect to the background */
    background-color: rgba(255, 255, 255, 0.0);
    border: 2px solid #00901f !important;
    min-height: 40px;
    border-radius: 15px;
    width: 400px;
    padding: 5px;
    color: #666;
    font-size: 20px;
    justify-self: center;
    display: block;
    margin-top: 30px;
    padding-left: 20px;
}
.validEmailField:focus {
    border-color: #666;
    outline: none; /* Removes the default focus highlight */
}

.passwordField {
    backdrop-filter: blur(10px); /* Apply a blur effect to the background */
    background-color: rgba(255, 255, 255, 0.0);
    border: 1px solid #999;
    min-height: 40px;
    border-radius: 15px;
    width: 400px;
    padding: 5px;
    color: #666;
    font-size: 20px;
    justify-self: center;
    display: block;
    margin-top: 30px;
    padding-left: 20px;
}
.passwordField:focus {
    border-color: #666;
    outline: none; /* Removes the default focus highlight */
}


.signInButton {
    background-color: #fff;
    color: #222;
    padding: 15px 60px 15px 60px;
    border-radius: 30px;
    margin-top: 40px;
    cursor: pointer;
    font-weight: 400;
    transition: background-color 0.5s ease; /* Add this line */
}

.signInButton:hover {
    background-color: #e0e0e0;
}

