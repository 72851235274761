

.LinksContainer {
    background-color: #fff;
    min-height: calc(100vh - 70px);
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.getLinksButton {
    color: #fff;
    background-color: rgb(44, 44, 44);
    padding: 15px 40px 15px 40px;
    border-radius: 30px;
    margin: 20px;
    cursor: pointer;
}

.linkRow {
    display: flex;
    flex-direction: row;
    user-select: none;
}

.linkContainer {
    display: block;
    padding: 20px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    margin: 10px;
    transition: background-color 0.3s;
    cursor: pointer;
    min-width: 600px;
}

.usedLinkContainer {
    display: block;
    padding: 20px;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: 10px;
    background-color: rgb(43, 95, 226);
    color: #fff;
    transition: background-color 0.3s;
    cursor: pointer;
    border: 1px solid #e7e7e7;
    min-width: 600px;
    
}

.usedLinkMarker {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px 10px 5px 10px;
    margin-right: 10px;
    border-radius: 20px;
    background-color: rgba(67, 67, 67, 0.3);
    color: #fff;

}

.selectedLinkTypeButton {
    margin-left: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 6px 10px 6px 10px;
    color: #fff;
    border-radius: 20px;
    line-height: 31px;
    transition: background-color 0.3s;
}
.selectedLinkTypeButton:hover {
    background-color: rgba(255, 255, 255, 0.3);

}

.linkTypeButton {
    margin-left: 20px;
    background-color: rgba(67, 67, 67, 0.3);
    padding: 6px 10px 6px 10px;
    color: #fff;
    border-radius: 20px;
    line-height: 31px;
    transition: background-color 0.3s;

}
.linkTypeButton:hover {
    background-color: rgba(67, 67, 67, 0.6);

}

@keyframes slideInAndOut {
    0%, 100% {
      transform: translateY(100%); /* Start and end outside the bottom of the screen */
      opacity: 0;
    }
    10%, 90% {
      transform: translateY(0); /* Fully visible for the middle portion of the animation */
      opacity: 1;
    }
  }

  .linksCopiedMessage {
    position: fixed;
    bottom: -100px; /* Initially off-screen */
    right: -200px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    transform: translateX(-50%);
    padding: 20px;
    transition: bottom 0.5s ease-in-out; /* Transition only the bottom property */
    z-index: 2000;
    border: 1px solid #e7e7e7;
}

.linksCopiedMessage.show {
    bottom: 60px; /* Slide in from the bottom */
}
