


.FullAutoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: calc(100vw - 352px);
    min-height: calc(100vh - 60px);
}

.FullAutoTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e7e7;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
}

.FullAutoTopItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.FullAutoTopItemTitle {
    display: block;
    text-align: left;
    width: 100%;

}

.FullAutoTopItemCardLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.FullAutoTopItemCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
}

.FullAutoTopItemCard:hover {
    border: 1px solid #e7e7e7;
    background-color: #f7f7f7;
    cursor: pointer;
}

.FullAutoTopItemCardLoadingText {
    display: block;
    color: #888;
    font-weight: 600;
    font-size: 12px;
    margin-top: 10px;

}

.FullAutoTopItemCardTitle {
    display: block;
    color: #000;
    font-weight: 600;
    font-size: 18px;

}

.FullAutoTopItemCardValue {
    display: block;
    color: #888;
    font-weight: 600;
    font-size: 12px;
}

.FullAutoMiddle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.FullAutoBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e7e7e7;
    width: 100%;
    height: 80px;

}

.FullAutoBottomBack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin: 20px 60px 20px 60px;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
}

.FullAutoBottomRightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.FullAutoBottomRightSavingText {
    display: block;
    color: #888;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
}

.FullAutoBottomNext {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 60px 20px 60px;
    background-color: #272727;
    color: white;
    width: 100px;
    height: 45px;
    border-radius: 10px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
}

.FullAutoBottomNextDisabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 60px 20px 60px;
    background-color: #27272766;
    color: white;
    width: 100px;
    height: 45px;
    border-radius: 10px;
    cursor: not-allowed;
    user-select: none;
    transition: all 0.3s ease;

}
