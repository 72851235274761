
.PlaygroundContainer {
    background-color: #fff;
    max-height: calc(100vh - 60px);
    min-height: calc(100vh - 60px);
    min-width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.PlaygroundLeftContainer {
    min-width: 20vw;
    max-width: 20vw;
    border-right: 1px solid #e7e7e7;
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    overflow: auto;

}

.PlaygroundLeftCreatePromptButton {
    background-color: #272727;
    color: #fff;
    border-radius: 30px;
    display: block;
    text-align: center;
    font-size: 16px;
    padding: 15px 0px 15px 0px;
    margin: 15px 15px 0px 15px;
    user-select: none;
    cursor: pointer;
}

.PlaygroundLeftToggleContainer {
    padding: 15px;
}

.PlaygroundExpandFilterOptionsContainer {
    width: 85%;
    margin: auto;
    padding: 10px 10px 10px 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;
}
.PlaygroundExpandFilterOptionsContainer:hover {
    background-color: #e7e7e7;
}

.PlaygroundExpandFilterOptionsTitle {
    font-weight: 400;
    font-size: 15px;
    display: block;
    padding-left: 10px;
    color: #222;
}   

.PlaygroundExpandFilterOptionsArrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;

}

.PlaygroundExpandFilterOptionsArrowRotated {
    transform: rotate(180deg);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
}

.PlaygroundFilterOptionsContainer {
    width: 85%;
    margin: auto;
    padding: 20px 10px 10px 10px;
    overflow: hidden;
    transition: all 0.5s;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.PlaygroundFilterOptionsSwitchRow {
    width: 100%;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.PlaygroundFilterOptionsSwitchLabel {
    display: block;
    padding-left: 20px;
    font-size: 14px;
    color: #444
}

.PlaygroundLeftPromptContainer {
    padding: 20px;
    margin: 5px 15px 5px 15px;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    user-select: none;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
}

.PlaygroundLeftPromptContainer:hover {
    background-color: #e7e7e7;

}

.PlaygroundLeftPromptName {
    display: block;
    font-weight: 600;
    font-size: 18px;
    
}

.PlaygroundLeftPromptTimeStamp {
    display: block;
    font-size: 10px;
    color: #666

}

.PlaygroundLeftPromptLevel {
    display: block;
    font-size: 10px;
    color: #666

}

.PlaygroundLeftPromptUses {
    display: block;
    font-size: 10px;
    color: #666

}

.PlaygroundLeftPromptUsers {
    position: absolute;
    top: 10px;
    right: 40px;
}







.PlaygroundRightContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    
}

.PlaygroundRightContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    min-width: 65vw
}

.PlaygroundTitleBar {
    height: 60px;
    font-size: 16px;
    color: #444;
    display: block;
    border-bottom: 1px solid #e7e7e7;
    width: 100% !important;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.PlaygroundTitleBarLeft {
    margin-left: 15px;
    margin-right: 150px;
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #fff;
    transition: all 0.5s;
}

.PlaygroundTitleBarLeft:hover {
    margin-left: 10px;
    border: 1px solid #e7e7e7;
    background-color: #e7e7e7;
}

.PlaygroundTitleBarRight {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.PlaygroundTitleBarRightIcon {
    margin-right: 10px;
    height: 40px;
    width: 40px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #fff;
    transition: all 0.5s;
}

.PlaygroundTitleBarRightIcon:hover {
    border: 1px solid #e7e7e7;
    background-color: #e7e7e7;
}


.PlaygroundTextareaWrapper {
    position: relative;
    width: 828px;
    margin: 20px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.PlaygroundTextareaLabel {
    font-size: 12px;
    position: absolute;
    top: -8px;
    left: 6px;
    display: block;
    background-color: #fff;
    padding: 0px 5px 0px 5px;
    color: #444
}

.PlaygroundTextarea {
    display: block;
    width: 800px;
    height: 400px;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #cdcdcd;
    color: #444;
    transition: border 0.5s, color 0.5s;
    padding: 10px;
    min-width: 300px;
    resize: none;
}

.PlaygroundTextarea:hover {
    border: 1px solid #8e8e8e;
}

.PlaygroundTextarea:focus {
    border: 2px solid #272727;
    color: #000
}

.PlaygroundInputWrapper {
    position: relative;
    width: 826px;
    height: 50px;
    margin: 10px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.PlaygroundInput {
    display: block;
    width: 100%;
    height: 20px;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #cdcdcd;
    color: #444;
    transition: border 0.5s, color 0.5s;
    padding: 10px;


}

.PlaygroundInput:hover {
    border: 1px solid #8e8e8e;
}

.PlaygroundInput:focus {
    border: 1px solid #272727;
    color: #000
}



.PlaygroundFromSubRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}


.PlaygroundDefaultAIModelRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    height: auto;
    border: 1px solid #e7e7e7;
    padding: 10px;
    border-radius: 15px;
    transition: all 0.5s;
}

.PlaygroundDefaultAIModelRowLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 250px;
    transition: all 0.5s;
    height: 50px
}



.PlaygroundAIModelSelectWrapper {
    position: relative;
    flex: 1;
    transition: all 0.5s;
}

.PlaygroundAIModelSelectLabel {
    display: block;
    padding: 0px 5px 0px 5px;
    position: absolute;
    left: 5px;
    top: -5px;
    background-color: #fff;
    font-size: 10px;
    color: #666;
    transition: all 0.5s;

}

.PlaygroundAIModelSelect {
    border: 1px solid #e5e5e5;
    min-height: 40px;
    border-radius: 10px;
    color: #000;
    font-size: 14px;
    justify-self: center;
    display: block;
    margin-top: 0px;
    padding-left: 10px;
    width: calc(100% - 20px);
    padding-top: 8px;
    transition: all 0.5s;

}





.PlaygroundRightRevisionsContainer {
    min-width: 15vw;
    max-width: 15vw;
    border-left: 1px solid #e7e7e7;
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    overflow: auto;
    position: relative;
}

.PlaygroundRightTitle {
    display: block;
    text-align: center;
    width: 100%;
    padding: 8px 0px 5px 0px;
}

.PlaygroundRevision {
    padding: 20px;
    margin: 5px 15px 5px 15px;
    border-radius: 20px;
    border: 1px solid #e7e7e7;
    user-select: none;
    cursor: pointer;
    position: relative;
    transition: all 0.5s;
}

.PlaygroundRevision:hover {
    background-color: #e7e7e7;

}

.PlaygroundRevisionName {
    display: block;
    font-weight: 600;
    font-size: 18px;
    
}

.PlaygroundRevisionChanges {
    display: block;
    font-size: 10px;
    color: #666
}

.PlaygroundRightPromptUsers {
    position: absolute;
    top: 10px;
    right: 0px;
}



.PlaygroundSharingContainer {
    margin-top: 150px;
    min-height: 600px;
    max-height: 600px;
    min-width: 500px;
    max-width: 500px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px;
    border-radius: 30px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: auto;
    outline: none;
    overflow: auto;
}

.PlaygroundSharingContainerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
}

.PlaygroundSharingContainerHeaderText {
    display: block;
    padding: 15px;

}

.PlaygroundSharingContainerHeaderIcon {
    margin: 0px 10px 0px 10px;
    height: 35px;
    width: 35px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #fff;
    transition: all 0.5s;
}

.PlaygroundSharingContainerHeaderIcon:hover {
    border: 1px solid #e7e7e7;
    background-color: #e7e7e7;
}

.PlaygroundSharingUserRowAvatar {
    color: white;
    font-weight: 600;
    font-size: 14px; /* original font size */
    width: 50px; /* original width */
    height: 50px; /* original height */
    font-size: 20px; /* overridden font size */
    border-radius: 40px; /* original border radius */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px; /* original margin right */
    cursor: pointer;
    user-select: none;
}

.PlaygroundSharingUserRowSelected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin: 10px 30px 10px 30px;
    border-radius: 15px;
    border: 2px solid #29ae04;
    padding: 10px;
    cursor: pointer;
    user-select: none;
}

.PlaygroundSharingUserRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin: 10px 30px 10px 30px;
    border-radius: 15px;
    border: 1px solid #e7e7e7;
    padding: 10px;
    cursor: pointer;
    user-select: none;
}

.PlaygroundSharingUserRow:hover {
    background-color: #e7e7e7;
}

.PlaygroundSharingUserName {
    font-size: 20px;
}

.PlaygroundSaveSharingButton {
    background-color: #272727;
    color: #fff;
    border-radius: 30px;
    display: block;
    text-align: center;
    font-size: 16px;
    padding: 15px 60px 15px 60px;
    margin: 15px 15px 0px 15px;
    user-select: none;
    cursor: pointer;
    width: fit-content;
}
